import {
  CourseCurriculum,
  CourseViewModel,
  ModuleViewModel,
} from '@hidayahlab/academy-suite-corelib';

export const CourseUtils = {
  getTotalModules: (curriculum: CourseViewModel['curriculum']) => {
    return curriculum?.length ?? 0;
  },
  getTotalCompletedModule: (curriculum: CourseViewModel['curriculum']) => {
    if (!curriculum) {
      return 0;
    }

    return curriculum.reduce((acc, curr) => {
      return acc + (curr.isCompleted ? 1 : 0);
    }, 0);
  },
  calculateProgress(curriculum: CourseViewModel['curriculum'], activity: any) {
    if (!curriculum) {
      console.error('No curriculum data provided');
      return;
    }

    if (!activity) {
      console.error('No activity data provided');
      return;
    }

    let totalContentCount = 0;
    let totalCompletedCount = 0;

    curriculum.forEach(module => {
      const totalContent = module.contents.length;
      totalContentCount += totalContent;

      module.contents.forEach(content => {
        const activityType = content.type === 'LESSON' ? 'LESSON' : 'QUIZ';
        const activityId = content.id;

        if (
          activity[activityType] &&
          activity[activityType][activityId] &&
          activity[activityType][activityId].status === 'COMPLETED'
        ) {
          totalCompletedCount++;
        }
      });
    });

    const overallProgress = (totalCompletedCount / totalContentCount) * 100;

    return {
      overallProgress: Math.round(+overallProgress),
    };
  },
  calculateLastInProgress: (curriculum: CourseViewModel['curriculum']) => {
    if (!curriculum) {
      return undefined;
    }
    const inProgressModules = curriculum?.filter(item =>
      item.contents.some(content => content.activityStatus === 'IN_PROGRESS'),
    );
    const lastInProgressModules =
      inProgressModules?.[inProgressModules.length - 1] ?? curriculum?.[0];
    return lastInProgressModules;
  },
  calculateTotalLessonsUsingCurriculum: (curriculums: CourseCurriculum[]) => {
    const totalLessons = curriculums.reduce((count, module) => {
      return (
        count +
        module.contents.filter(content => content.type === 'LESSON').length
      );
    }, 0);
    return totalLessons;
  },
  calculateTotalLessonsUsingModules: (modules?: ModuleViewModel[]) => {
    const totalLessons = modules?.reduce(
      (acc, module) => acc + (module._count?.lessons ?? 0),
      0,
    );
    return totalLessons;
  },
  curriculumContentTypeToLinkMap: {
    LESSON: (
      courseSlug: string,
      slug: string,
      id?: number,
      courseId?: string | number,
    ) => `/my-classes/${courseId}/${courseSlug}/contents/${id}/${slug}`,
    QUIZ: (
      courseSlug: string,
      slug: string,
      id?: number,
      courseId?: string | number,
    ) => `/my-classes/${courseId}/${courseSlug}/quizzes/${id}/${slug}`,
    RESOURCE: (
      courseSlug: string,
      slug: string,
      id?: number,
      courseId?: string | number,
    ) => `/my-classes/${courseId}/${courseSlug}/resources/${id}/${slug}`,
  },
  calculateDiscountedPrice: (price: number, discountPercentage = 0) => {
    const discountAmount = price * (discountPercentage / 100);

    let discountedPrice = price - discountAmount;

    discountedPrice = Math.round(discountedPrice);

    return discountedPrice;
  },
};
