import { Link } from '@/i18n/routing';
import { CourseUtils } from '@/utils/course-utils';
import { fallbackImages } from '@/utils/fallback-images';
import { FilePathUtils } from '@/utils/file-path-utils';
import { CourseViewModel } from '@hidayahlab/academy-suite-corelib';
import { DotIcon } from '@tajdid/tajdid-ui/icons';
import Image from 'next/image';

type CourseCardProps = {
  course: CourseViewModel;
};

export default function CourseCardPrimary({ course }: CourseCardProps) {
  const discountPrice = CourseUtils.calculateDiscountedPrice(
    course.price,
    course.discount,
  );

  return (
    <Link
      href={`/courses/${course.id}/${course.slug}`}
      className=" bg-white border border-white cursor-pointer hover:scale-[103%] hover:shadow-lg hover:border hover:border-primary-500 transition-all duration-300 rounded-md p-4 pb-8 relative"
    >
      <div>
        <div className="relative w-full aspect-[4/3] rounded-md overflow-hidden mb-4">
          <Image
            src={
              course.thumbnail
                ? `${FilePathUtils.courseImagePath(course.id)}/${course.thumbnail}`
                : '/Images/course-placeholder.jpg'
            }
            alt={course.title}
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div className="grid gap-3">
          <h3 className="text-lg text-gray-700 font-semibold">
            {course.title}
          </h3>
          <p className="text-sm text-gray-700">{course.description}</p>

          <div className="flex items-center gap-3">
            <p className="text-primary-500 text-2xl font-bold ">
              {course.isFree && course.price === 0
                ? 'Free'
                : `$${discountPrice}`}
            </p>
            {!course.isFree && course.price >= 0 && (
              <p className="line-through lg:mt-2 text-body font-semibold">
                $ {course.price}
              </p>
            )}
          </div>
        </div>
      </div>
      {course.format === 'LIVE' && (
        <div className="absolute top-8 right-8 font-sm font-semibold bg-[#FA4542] text-white rounded-[6px] px-2 py-1 flex items-center gap-1">
          <DotIcon height={8} width={8} />
          Live
        </div>
      )}
    </Link>
  );
}
