'use client';

import { CourseCardPrimary } from '@/components/course-card';
import { CourseViewModel } from '@hidayahlab/academy-suite-corelib';
import { Button } from '@tajdid/tajdid-ui';
import { ArrowLeft01Icon, ArrowRight01Icon } from 'hugeicons-react';
import Title from './title';

export type CourseProps = {
  courses: CourseViewModel[];
  liveCourses: CourseViewModel[];
};

export default function TopCourse({ courses, liveCourses }: CourseProps) {
  return (
    <section className="py-5 lg:py-8 xl:py-15">
      <div className="flex justify-between items-center mb-4">
        <Title>Top courses in Development</Title>
        <div className="flex gap-4">
          <Button
            variant="outlined"
            disabled
            className="disabled:text-gray-400 disabled:border-gray-400 disabled:bg-transparent border-2 p-1 rounded-sm disabled:shadow-none min-w-0"
          >
            <ArrowLeft01Icon />
          </Button>
          <Button
            variant="outlined"
            className="disabled:text-gray-400 disabled:border-gray-400 disabled:bg-transparent border-2 p-1 rounded-sm disabled:shadow-none min-w-0"
          >
            <ArrowRight01Icon />
          </Button>
        </div>
      </div>
      <div className="grid  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {courses.map(course => (
          <CourseCardPrimary key={course.id} course={course} />
        ))}
        {liveCourses.map(course => (
          <CourseCardPrimary key={course.id} course={course} />
        ))}
      </div>
    </section>
  );
}
