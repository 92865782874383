import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/components/clients-review.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/components/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/components/popular-certificate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/components/recent-view-product.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/components/top-course.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/components/work-ethics.tsx");
